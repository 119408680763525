import * as actionTypes from './actionTypes';

import { sendLogError } from './error';
import jwt_decode from 'jwt-decode';
import customaxios from '../../config/axios-refresh-token';
import { getUserInfo } from './user';

const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (token, userId) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token,
    userId,
  };
};

const authError = error => {
  return {
    type: actionTypes.AUTH_ERROR,
    error,
  };
};

const firstLoginSuccess = (token, refreshToken) => {
  return {
    type: actionTypes.FIRST_LOGIN_SUCCESS,
    token,
    refreshToken,
  };
};

const firstLoginError = error => {
  return {
    type: actionTypes.FIRST_LOGIN_ERROR,
    error: error,
  };
};

export const clearFirstAccessError = () => {
  return {
    type: actionTypes.CLEAR_FIRST_LOGIN_ACCESS,
  };
};

export const authInit = token => {
  let endpoint = 'auth/signinalpitour';
  return dispatch => {
    customaxios
      .post(endpoint, { token })
      .then(result => {
        dispatch(firstLoginSuccess(result.data.token));
        localStorage.setItem('token', result.data.token);
        dispatch(setRedirect());
      })
      .catch(error => {
        dispatch(firstLoginError(error.response.data.error));
        if (error.response.status >= 500) {
          dispatch(sendLogError(endpoint, error));
        }
      });
  };
};

const signinStart = () => {
  return {
    type: actionTypes.SIGNIN_START,
  };
};
const signinSuccess = (token, userId) => {
  return {
    type: actionTypes.SIGNIN_SUCCESS,
    token,
    userId,
  };
};
const signinError = error => {
  return {
    type: actionTypes.SIGNIN_ERROR,
    error,
  };
};
export const signinClearError = () => {
  return {
    type: actionTypes.SIGNIN_CLEAR_ERROR,
  };
};

export const signinInit = (username, password) => {
  let endpoint = 'auth/signin';
  return dispatch => {
    dispatch(signinStart());
    customaxios
      .post('auth/signin', {
        username,
        password,
      })
      .then(result => {
        dispatch(signinSuccess(result.data.token));
        localStorage.setItem('token', result.data.token);
        dispatch(setRedirect());
      })
      .catch(error => {
        const {
          response: { status },
        } = error;
        if (status >= 400) {
          if (status === 403) {
            dispatch(signinError(2000));
          } else {
            dispatch(signinError(400));
          }
        }
        if (status >= 500) {
          dispatch(sendLogError(endpoint, error));
        }
      });
  };
};

const signupStart = () => {
  return {
    type: actionTypes.SIGNUP_START,
  };
};

const signupSuccess = data => {
  return {
    type: actionTypes.SIGNUP_SUCCESS,
    data,
  };
};

const signupError = error => {
  return {
    type: actionTypes.SIGNUP_ERROR,
    error,
  };
};

export const signupClearError = () => {
  return {
    type: actionTypes.SIGNUP_CLEAR_ERROR,
  };
};

export const signUpInit = data => {
  let endpoint = 'auth/signup';
  return dispatch => {
    dispatch(signupStart());

    customaxios
      .post(endpoint, data)
      .then(result => {
        dispatch(signupSuccess());
      })
      .catch(error => {
        const {
          response: {
            status,
            data: { error: message },
          },
        } = error;
        if (status === 400) {
          switch (message) {
            case 'There is another user with the same email':
              dispatch(signupError(2010));
              break;
            default:
              dispatch(signupError(message));
              break;
          }
        }
        if (status >= 500) {
          dispatch(sendLogError(endpoint, error));
        }
      });
  };
};

export const setRedirect = () => {
  return {
    type: actionTypes.SET_REDIRECT,
  };
};

export const setRedirectFalse = () => {
  return {
    type: actionTypes.SET_REDIRECT_FALSE,
  };
};

export const checkAuthState = () => {
  return dispatch => {
    dispatch(authStart());
    const token = localStorage.getItem('token');
    if (token && token !== 'undefined') {
      dispatch(authSuccess(token));
    } else {
      dispatch(authError('user not logged'));
    }
  };
};

const authLogoutStart = () => {
  return {
    type: actionTypes.LOGOUT_START,
  };
};

export const authLogoutSuccess = () => {
  return {
    type: actionTypes.LOGOUT_SUCCESS,
  };
};
const authLogoutError = () => {
  return {
    type: actionTypes.LOGOUT_ERROR,
  };
};

export const logout = refreshToken => {
  let endpoint = 'auth/signout';
  return dispatch => {
    dispatch(authLogoutStart());
    customaxios({
      method: 'post',
      url: 'auth/signout',
      data: {
        refresh_token: refreshToken,
      },
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
      //.get(endpoint, { data: { refresh_token: refreshToken } })
      .then(result => {
        dispatch(setRedirectFalse());
        dispatch(authLogoutSuccess());
        localStorage.removeItem('token');
        localStorage.removeItem('refresh-token');
        localStorage.removeItem('webview');
        window.location.href = process.env.REACT_APP_COGNITO_LOGOUT;
      })
      .catch(error => {
        dispatch(setRedirectFalse());
        localStorage.removeItem('token');
        localStorage.removeItem('refresh-token');
        dispatch(authLogoutError());
        if (error.response.status >= 500) {
          dispatch(sendLogError(endpoint, error));
        }
      });
  };
};

const recoveryPasswordStart = () => {
  return {
    type: actionTypes.RECOVERY_PASSWORD_START,
  };
};
const recoveryPasswordSuccess = () => {
  return {
    type: actionTypes.RECOVERY_PASSWORD_SUCCESS,
  };
};
const recoveryPasswordError = error => {
  return {
    type: actionTypes.RECOVERY_PASSWORD_ERROR,
    error,
  };
};

export const recoveryPasswordClearError = () => {
  return {
    type: actionTypes.RECOVERY_PASSWORD_CLEAR_ERROR,
  };
};

export const recoveryPassword = email => {
  let endpoint = 'auth/forgot/recovery';
  return dispatch => {
    dispatch(recoveryPasswordStart());
    customaxios
      .post(endpoint, {
        email,
      })
      .then(result => {
        dispatch(recoveryPasswordSuccess());
      })
      .catch(error => {
        const {
          response: {
            status,
            data: { error: message },
          },
        } = error;
        if (status === 400) {
          if (message === 'User not found' || message === 'User not related to this application') {
            dispatch(recoveryPasswordError(2020));
          } else {
            dispatch(recoveryPasswordError(400));
          }
        }
        if (status >= 500) {
          dispatch(sendLogError(endpoint, error));
        }
      });
  };
};

const resetPasswordStart = () => {
  return {
    type: actionTypes.RESET_PASSWORD_START,
  };
};

const resetPasswordSuccess = () => {
  return {
    type: actionTypes.RESET_PASSWORD_SUCCESS,
  };
};

const resetPasswordError = error => {
  return {
    type: actionTypes.RESET_PASSWORD_ERROR,
    error,
  };
};

export const resetPasswordClearError = () => {
  return {
    type: actionTypes.RESET_PASSWORD_CLEAR_ERROR,
  };
};

export const resetPassword = onetimetoken => {
  let endpoint = `auth/forgot/reset/${onetimetoken}`; // password forgot
  return dispatch => {
    dispatch(resetPasswordStart());
    customaxios
      .get(endpoint)
      .then(result => {
        dispatch(resetPasswordSuccess());
      })
      .catch(error => {
        const {
          response: {
            status,
            data: { error: message },
          },
        } = error;
        if (status === 400 || status === 422) {
          if (message === 'User not related to this application') {
            dispatch(resetPasswordError(2020));
          } else {
            dispatch(resetPasswordError(2040));
          }
        }
        if (status >= 500) {
          dispatch(sendLogError(endpoint, error));
        }
      });
  };
};

const resetChangePasswordStart = () => {
  return {
    type: actionTypes.RESET_CHANGE_PASSWORD_START,
  };
};

const resetChangePasswordSuccess = () => {
  return {
    type: actionTypes.RESET_CHANGE_PASSWORD_SUCCESS,
  };
};

const resetChangePasswordError = error => {
  return {
    type: actionTypes.RESET_CHANGE_PASSWORD_ERROR,
    error,
  };
};

export const resetChangePasswordClearError = () => {
  return {
    type: actionTypes.RESET_CHANGE_PASSWORD_CLEAR_ERROR,
  };
};

export const resetChangePassword = (password, confirm, onetimetoken) => {
  let endpoint = `auth/forgot/reset`;
  return dispatch => {
    dispatch(resetChangePasswordStart());
    customaxios
      .post(endpoint, {
        password,
        confirm,
        onetimetoken,
      })
      .then(result => {
        dispatch(resetChangePasswordSuccess());
      })
      .catch(error => {
        const {
          response: {
            status,
            data: { error: message },
          },
        } = error;
        if (status === 400) {
          if (message === 'User not related to this application') {
            dispatch(resetChangePasswordError(2020));
          } else {
            dispatch(resetChangePasswordError(2040));
          }
        }
        if (status === 422) {
          if (message === 'Password and confirm password are not the same') {
            dispatch(resetChangePasswordError(2030));
          }
        }
        if (status >= 500) {
          dispatch(sendLogError(endpoint, error));
        }
      });
  };
};

const checkAuthStart = () => {
  return {
    type: actionTypes.CHECK_AUTH_START,
  };
};

const checkAuthSuccess = () => {
  return {
    type: actionTypes.CHECK_AUTH_SUCCESS,
  };
};

const checkAuthError = () => {
  return {
    type: actionTypes.CHECK_AUTH_ERROR,
  };
};

const cognitoAuthLoading = () => {
  return {
    type: actionTypes.AUTH_COGNITO_LOADING,
  };
};

const cognitoAuthEnd = () => {
  return {
    type: actionTypes.AUTH_COGNITO_END,
  };
};

export const cognitoAuth = (id_token, access_token, refresh_token, testid, token, webview) => {
  let endpoint = `auth/cognito`;
  return dispatch => {
    dispatch(cognitoAuthLoading());
    return new Promise((resolve, reject) => {
      const data = { id_token, access_token, refresh_token };
      if (testid) {
        data.testid = testid;
      }
      if (token) {
        data.token = token;
      }
      customaxios
        .post(endpoint, data)
        .then(result => {
          dispatch(cognitoAuthEnd());
          dispatch(firstLoginSuccess(result.data.token, refresh_token));
          localStorage.setItem('token', result.data.token);
          localStorage.setItem('refresh-token', refresh_token);
          webview && localStorage.setItem('webview', webview);
          resolve();
        })
        .catch(error => {
          dispatch(cognitoAuthEnd());
          dispatch(checkAuthError());
          localStorage.removeItem('token');
          localStorage.removeItem('refresh-token');
          resolve(error);
          if (error.response.status >= 500) {
            dispatch(sendLogError(endpoint, error));
          }
        });
    });
  };
};

export const checkAuth = () => {
  let endpoint = `auth/check`;
  return dispatch => {
    dispatch(checkAuthStart());
    return new Promise((resolve, reject) => {
      customaxios
        .get(endpoint)
        .then(result => {
          dispatch(checkAuthSuccess());
          resolve();
        })
        .catch(error => {
          dispatch(checkAuthError());
          resolve(error);
          if (error.response.status >= 500) {
            dispatch(sendLogError(endpoint, error));
          }
        });
    });
  };
};

const oauthSendTokenStart = () => {
  return {
    type: actionTypes.OAUTH_SEND_TOKEN_START,
  };
};

const oauthSendTokenSuccess = token => {
  return {
    type: actionTypes.OAUTH_SEND_TOKEN_SUCCESS,
    token,
  };
};

const oauthSendTokenError = error => {
  return {
    type: actionTypes.OAUTH_SEND_TOKEN_ERROR,
    error,
  };
};

export const oauthSendToken = (oauthtoken, oauthprovider, data) => {
  let endpoint = `/auth/oauth`; // oauth signin
  return dispatch => {
    dispatch(oauthSendTokenStart());
    //dispatch(authStart());
    customaxios
      .post(endpoint, {
        oauthtoken,
        oauthprovider,
        oauthdata: JSON.stringify(data),
      })
      .then(result => {
        dispatch(oauthSendTokenSuccess(result.data.token));
        //dispatch(authSuccess(result.data.token));
        localStorage.setItem('token', result.data.token);
        //dispatch(setRedirect()); // forse si o forse no
        dispatch(setRedirect()); // ora funziona per fb e google e stardust
      })
      .catch(error => {
        dispatch(oauthSendTokenError(error.response.status));
        const {
          response: {
            status,
            data: { error: message },
          },
        } = error;
        if (status >= 500) {
          dispatch(sendLogError(endpoint, error));
        }
      });
  };
};

const activateUserSuccess = first_access_done => {
  return {
    type: actionTypes.ACTIVATE_USER_SUCCESS,
    first_access_done,
  };
};

const activateUserError = error => {
  return {
    type: actionTypes.ACTIVATE_USER_ERROR,
    error: error,
  };
};

const activateUserStart = () => {
  return {
    type: actionTypes.ACTIVATE_USER_START,
  };
};

export const activateUser = obj => {
  let endpoint = 'auth/me/activate';
  return dispatch => {
    dispatch(activateUserStart());

    return customaxios
      .post(endpoint, { ...obj, privacy: 1 })
      .then(result => {
        const resultActivate = result;
        customaxios
          .post(`/auth/me/regulation_view`)
          .then(result => {
            dispatch(activateUserSuccess(resultActivate.data.user.first_access_done));
            dispatch(setRedirect());
            dispatch(getUserInfo());
          })
          .catch(error => {
            console.log(error);
          });
      })
      .catch(error => {
        dispatch(activateUserError(error.response.data.error || 'Qualcosa è andato storto'));
      });
  };
};
