import React from 'react';
import { Wrapper, Text, Flex } from '../Ui';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { KeyboardArrowUp } from '@styled-icons/material';
import { respondTo } from '../../theme/mixin';
import imgFooter from '../../assets/images/logo-alpitour-world.png';
import logoAlpitourWorld from '../../assets/images/iconFooter.svg';
import logoAlpitourIt from '../../assets/images/logo-alpitourit.png';
import logoTurisanda from '../../assets/images/logo-turisanda.jpg';
import logoEdenviaggi from '../../assets/images/logo-edenviaggi.png';
import { useSelector } from 'react-redux';

const CustomWrapper = styled(Wrapper)`
  margin-top: 30px;
  ${({ inHome }) =>
    !inHome &&
    css`
      border-bottom: 8px solid ${({ theme }) => theme.primary};
      border-top-right-radius: 15px;
      border-top-left-radius: 15px;
      box-shadow: ${({ theme }) => theme.shadow};
    `}

  padding-top: 15px;
  background-color: ${({ inHome }) => (inHome ? 'rgb(242 190 76)' : '#fff')};
  .text_color {
    color: ${({ theme }) => theme.text_link};
    margin-bottom: 10px;
  }
`;

const TopBar = styled.div`
  text-align: center;
  margin-bottom: 10px;

  img {
    max-width: ${props => props.theme.footer_logo_size}px;
  }
`;

const IconUp = styled(KeyboardArrowUp)`
  cursor: pointer;
  color: ${props => props.theme.primary};
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  height: 30px;
  border: 2px solid ${props => props.theme.primary};
  border-radius: 50%;
  transition: 0.3s;
  &:hover {
    background-color: ${props => props.theme.primary};
    color: #fff;
  }
`;

const BrandBar = styled.div`
  background-color: ${({ inHome }) => (inHome ? 'rgb(242 190 76)' : '#fff')};
  padding: 45px 0;
  border-radius: 15px;
  margin-top: 30px;
  ${({ inHome }) => !inHome && 'box-shadow: 0px 5px 15px #00000026'};
  .brand-bar-row {
    display: flex;
    flex-wrap: wrap;

    ${respondTo.sm`
      justify-content: center;
      flex-wrap: no-wrap;
    `}

    .col-left {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      padding: 0 15px;
      ${respondTo.sm`
      padding: 0 ;
        flex-wrap: no-wrap;
        border-right: 1px solid #1f7ac1;
        padding-right: 40px;
        margin-right: 50px;
        margin-bottom: 0;
      `}

      img {
        margin-left: 20px;
        width: 150px;
      }
    }

    .col-right {
      padding: 0 15px;
      .row-brands {
        margin-top: 12px;
        display: block;

        ${respondTo.sm`
        padding: 0 ;
          display: flex;
          align-items: center;
        `}

        .imgAlpitourIt {
          width: 136px;

          ${respondTo.sm`
            width: 106px;
          `}
        }

        .imgTurisanda {
          width: 152px;

          ${respondTo.sm`
            width: 122px;
          `}
        }

        .imgEdenviaggi {
          width: 155px;

          ${respondTo.sm`
            width: 135px;
          `}
        }

        div {
          margin-right: 20px;
          margin-bottom: 10px;

          &:last-child {
            margin-right: 0;
            margin-bottom: 0;
          }

          ${respondTo.sm`
            margin-bottom: 0;
            margin-right: 40px;
          `}
        }
      }
      ${respondTo.sm`
        padding: 0 ;

        `}
    }
  }
`;
const Container = styled.div`
  ${({ inHome }) => inHome && 'background-color:rgb(242 190 76)'};
  max-width: 1320px;
  margin: 0 auto;
  width: 100%;
`;

const Footer = ({ footer_brand_bar, inHome }) => {
  const { end } = useSelector(
    state =>
      state.promo.allpromo?.active?.find(item => item.slug === 'sunny-friday')?.settings || {},
  );
  return (
    <Container inHome={new Date() < new Date(end)}>
      <Wrapper inHome={new Date() < new Date(end)} style={{ padding: 0 }}>
        {footer_brand_bar && (
          <BrandBar inHome={new Date() < new Date(end)}>
            <div className='brand-bar-row'>
              <div className='col-left'>
                <Text>
                  You and Sun è il
                  <br />
                  programma fedeltà di:
                </Text>
                <img src={imgFooter} alt='' />
              </div>
              <div className='col-right'>
                <Text>Scopri i nostri siti e trova le offerte migliori per te:</Text>
                <div className='row-brands'>
                  <div>
                    <a
                      href='https://ad.doubleclick.net/ddm/trackclk/N1262013.3950266ALPITOUR/B28048469.339267797;dc_trk_aid=531189518;dc_trk_cid=173403049;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;ltd='
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <img src={logoAlpitourIt} alt='' className='imgAlpitourIt' />
                    </a>
                  </div>

                  <div>
                    <a
                      href='https://ad.doubleclick.net/ddm/trackclk/N1262013.4398718EDENVIAGGI/B28048469.339543979;dc_trk_aid=531324717;dc_trk_cid=173394697;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;ltd='
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <img src={logoEdenviaggi} alt='' className='imgEdenviaggi' />
                    </a>
                  </div>

                  <div>
                    <a
                      href='https://ad.doubleclick.net/ddm/trackclk/N1262013.4303345TURISANDA/B28048469.339394275;dc_trk_aid=531189563;dc_trk_cid=173403898;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;ltd='
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <img src={logoTurisanda} alt='' className='imgTurisanda' />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </BrandBar>
        )}
      </Wrapper>
      <CustomWrapper inHome={new Date() < new Date(end)}>
        <Flex direction='column' justify='center' align='center'>
          {!footer_brand_bar && (
            <TopBar>
              <img src={imgFooter} alt='' />
            </TopBar>
          )}

          <Text bold upper size={9} as='div' width='75%' align='center'>
            COPYRIGHT © 2022 | ALPITOUR S.P.A. SEDE LEGALE: VIA LUGARO, 15 - 10126 TORINO - ITALIA |
            CAPITALE SOCIALE SOTTOSCRITTO E VERSATO: 91.262.014€ | CODICE FISCALE: 02933920015 |
            PARTITA IVA: 02486000041
          </Text>
          <Text className='text_color' size={9} align='center'>
            <a
              href={`${process.env.PUBLIC_URL}/pdf/PrivacyPolicyAlpitour.pdf`}
              target='_blank'
              rel='noopener noreferrer'
            >
              PRIVACY POLICY{' '}
            </a>
            |{' '}
            <Link to='/cookie-policy' target='_blank'>
              COOKIE POLICY{' '}
            </Link>
            |{' '}
            <a
              href={`${process.env.PUBLIC_URL}/pdf/INFORMATIVA PRIVACY You and Sun.pdf`}
              target='_blank'
              rel='noopener noreferrer'
            >
              INFORMATIVA PRIVACY You and Sun{' '}
            </a>
            |{' '}
            <a
              href={`${process.env.PUBLIC_URL}/pdf/regolamento.pdf`}
              target='_blank'
              rel='noopener noreferrer'
            >
              REGOLAMENTO You and Sun{' '}
            </a>
            |{' '}
            <a
              href={`${process.env.PUBLIC_URL}/pdf/Regolamento_OP_OLTRE_LA_VACANZA.pdf`}
              target='_blank'
              rel='noopener noreferrer'
            >
              REGOLAMENTO Oltre la Vacanza{' '}
            </a>
            |{' '}
            <a
              href={`${process.env.PUBLIC_URL}/pdf/Regolamento_la_tua_passione_ti_premia.pdf`}
              target='_blank'
              rel='noopener noreferrer'
            >
              REGOLAMENTO La tua passione ti premia
            </a>
          </Text>
        </Flex>
        <IconUp size={30} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} />
      </CustomWrapper>
    </Container>
  );
};

export default Footer;
