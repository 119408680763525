import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import logoSlider from '../../assets/images/iconApp.svg';
import { respondTo } from '../../theme/mixin';
import { Button, Logo, Text } from '../Ui';
import { ReactComponent as BannerIcon } from '../../assets/images/icon_file.svg';
import PointsGame from '../travelGame/pointsGame';

const Container = styled.div`
  width: 100%;
  position: relative;
  padding: 15px;
  overflow: hidden;
  height: ${({ inFlex }) => (inFlex ? 'auto' : '100%')};
  z-index: 1;
  ${({ linear, linearPosition }) =>
    linear &&
    css`
      &:after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-image: linear-gradient(to top, #f0f0f0b5, transparent);
        ${respondTo.md`
       background-image: linear-gradient( ${
         linearPosition ? linearPosition : 'to top'
       }, #f0f0f0b5, transparent);
    `}
      }
    `};
  ${respondTo.md`
      padding: 10px;
    `}

  & > img,picture>* {
    object-fit: cover;
    object-position: bottom right;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .contentColor {
    color: ${({ darkText }) => (darkText || darkText === undefined ? '#000' : '#fff')};
  }

  .titleColor {
    color: ${({ darkText, theme }) =>
      darkText || darkText === undefined ? theme.primary : '#fff'};
  }

  .hiw-logo {
    display: none;
    ${respondTo.sm`
    display: block;
    position: absolute;
    width: 170px;
    top: 36%;
    left: 60px;
    transform: translateY(-40%);  
    `}
    ${respondTo.md`
    display: block;
    position: absolute;
    width:250px;
    top: 40%;
    left: 100px;
    transform: translateY(-40%);  
    `}
  }

  .hiw-line {
    width: 100px;
    height: 3px;
    z-index: 1;
    margin: 10px 0;
    background-color: ${({ darkText, theme }) =>
      darkText || darkText === undefined ? theme.primary : '#fff'};
  }

  .hiw-text {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    width: 100%;
    z-index: 1;
    ${respondTo.sm`
      width: ${({ fullWidth }) => (fullWidth ? '100%' : '40%')};  
    `}
    img {
      display: none;
      ${respondTo.sm`
      display: block;
      width: 63px; 
    `}
    }
    ${({ marginRight }) => (marginRight ? 'margin-right: ' + marginRight + ';' : '')}
    ${({ marginLeft }) => (marginLeft ? 'margin-left: ' + marginLeft + ';' : '')}
    ${({ padding }) => (padding ? 'padding: ' + padding + ';' : '')}
  }
  .textasterisk {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    width: 100%;
    z-index: 1;
    position: absolute;
    bottom: 100px;
    right: 0;
    font-style: italic;
    ${respondTo.sm`
      right: unset;
      left: -100px;
    `}
  }
`;

function ContentSlide({
  size,
  textBold,
  xBold,
  title,
  text,
  textasterisk,
  img,
  logo,
  icon,
  noBtn,
  noBtnLogin,
  linear,
  isLogged,
  link,
  scopri,
  imgMobile,
  left,
  dayCounterGame,
  partecipaText,
  textBtn,
  darkText,
  subtitle,
  ...rest
}) {
  const token = useSelector(state => state.auth?.token);

  return (
    <Container className='hiw-content' linear={linear} darkText={darkText} {...rest}>
      {dayCounterGame && <PointsGame />}
      {(img || imgMobile) && (
        <img src={window.innerWidth <= 768 && imgMobile ? imgMobile : img} alt='' />
      )}

      {logo && <Logo logo={logoSlider} className='hiw-logo' />}
      <div className='hiw-text'>
        {icon && <img src={icon} className='hiw-icon' alt='' />}
        <div>
          {title && (
            <>
              <Text
                as='div'
                size={size ? size : 18}
                bold
                style={{ fontWeight: xBold }}
                className='titleColor'
              >
                {title}
              </Text>
              <div className='hiw-line'></div>
            </>
          )}

          <Text as='div' size={14} margin='0 0 25px 0' bold={textBold} className='contentColor'>
            {text}
          </Text>

          {!noBtn ? (
            token ? (
              <Link to={link}>
                <Button
                  active={darkText || darkText === undefined}
                  upper
                  hover
                  className='travel-premi'
                  white={!darkText && darkText !== undefined}
                  darkText={darkText}
                  bold
                >
                  {partecipaText ? 'ISCRIVITI SUBITO' : textBtn ? textBtn : 'SCOPRI DI PIÙ'}
                </Button>
              </Link>
            ) : (
              !noBtnLogin && (
                <a href={process.env.REACT_APP_COGNITO_LOGIN}>
                  <Button active upper hover className='travel-premi'>
                    {partecipaText ? 'ISCRIVITI SUBITO' : 'SCOPRI DI PIÙ'}
                  </Button>
                </a>
              )
            )
          ) : null}

          {subtitle && (
            <Text as='div' size={14} margin='25px 0 0 0' bold={textBold} className='contentColor'>
              {subtitle}
            </Text>
          )}
        </div>
      </div>

      {textasterisk && (
        <>
          <Text as='div' size={14} margin='0 0 25px 0' className='textasterisk'>
            *{textasterisk}
          </Text>
        </>
      )}
    </Container>
  );
}

export default ContentSlide;
