import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import Footer from '../../components/Footer/Footer';
import { hotjar } from 'react-hotjar';
import LogoHeader from '../../assets/images/iconApp.svg';

const FirstAccess = props => {
  const firstAccess = useSelector(state => state.user.user.first_access_done);
  const footercontent = useSelector(state => state.cms.footer);

  useEffect(() => {
    hotjar.initialize(3032126, 6);
  }, []);

  useEffect(() => {
    if (firstAccess) {
      props.history.push('/');
    }
  }, [firstAccess, props.history]);

  return (
    <Wrapper>
      <div className='content'>
        <img src={LogoHeader} alt='' />
        <h2>Non è più possibile iscriversi all'iniziativa You&Sun</h2>
      </div>
      <Footer footercontent={footercontent} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100svh;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;

  .content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 15px;

    img {
      margin-bottom: 30px;
    }
  }

  div:nth-child(2) {
    margin-top: auto;
  }
`;

export default FirstAccess;
